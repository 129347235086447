import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Layout from '../components/layouts'
import HeadMeta from '../components/HeadMeta'
import * as ds from '../uiLibrary'
import { GatsbyHeroImage } from '../components/heroes'
import { Headline, H2, H4 } from '../uiLibrary/Typographyv2'
import {
  GatsbyImageSection,
  InfoContainerSection,
  AngledButton,
  TriangleDiv,
} from '../components'
import ChampionValues from '../components/ChampionValues'
import { LocationContext } from '../pages/index'
import { useLocale } from '../hooks/useLocale'
import { Banner } from '../components/Banner'

export const CommunityPage = ({ data, ...props }) => {
  const page = data.allCommunityJson.edges[0].node
  const bannerImage = data.banner.childImageSharp
  const imageSectionData = data.imageSections.edges.map(({ node }, i) => {
    return { image: node.childImageSharp, ...page.imageSections[i] }
  })
  const screenshots = data.screenshots.edges.reduce((acc, curr, i) => {
    acc[`shot${i}`] = curr
    return acc
  }, {})

  const currentLocale = useLocale(props.location.pathname)

  // v2 banner doesn't have embed text in image
  const BannerImage = page.bannerHeadline ? Banner : GatsbyHeroImage

  return (
    <LocationContext.Provider value={props.location}>
      <Layout locale={currentLocale}>
        <HeadMeta
          pageTitle={page.pageTitle || 'Tonit'}
          pageDescription={page.pageDescription}
          socialTitle={page.socialTitle}
          socialDescription={page.socialDescription}
          socialImage={page.socialImage}
        />
        <ds.Background background={'#000000'}>
          <ds.FixedWidthContainer
            py={[ds.spacing.s5, ds.spacing.s6, ds.spacing.s6]}
          >
            <BannerImage
              url={bannerImage.fluid}
              headline={page.bannerHeadline}
              accent={ds.brandColors.mediumRed}
            />
            <Headline
              textAlign={'center'}
              px={[ds.spacing.s3, ds.spacing.s4, ds.spacing.s5]}
              fontSize={[ds.typeScale.t6, ds.typeScale.t7, '4.998rem']}
              color={ds.brandColors.mediumRed}
            >
              {page.heroHeading}
            </Headline>
          </ds.FixedWidthContainer>
        </ds.Background>
        <ds.Background background="#202020">
          <InfoContainerSection
            {...page.introSection}
            pt={[ds.spacing.s6, ds.spacing.s6, ds.spacing.s3]}
            pb={ds.spacing.s4}
            index={1}
            triangle={true}
          />
          {imageSectionData.map((m, i) => {
            if (i === 0) {
              return (
                <React.Fragment key={i}>
                  <GatsbyImageSection
                    key={m.image.id}
                    url={m.image.fluid}
                    {...m}
                  />
                  <InfoContainerSection
                    {...page.secondarySection}
                    image={screenshots.shot0.node.childImageSharp.fluid}
                    pt={ds.spacing.s5}
                    pb={ds.spacing.s6}
                    index={1}
                  />
                  <InfoContainerSection
                    {...page.thirdSection}
                    image={screenshots.shot1.node.childImageSharp.fluid}
                    pt={ds.spacing.s5}
                    pb={[ds.spacing.s5, ds.spacing.s7]}
                    index={1}
                  />
                  <InfoContainerSection
                    {...page.lastSection}
                    pt={ds.spacing.s5}
                    pb={ds.spacing.s5}
                    index={1}
                  />
                </React.Fragment>
              )
            } else {
              return (
                <GatsbyImageSection
                  key={m.image.id}
                  url={m.image.fluid}
                  {...m}
                />
              )
            }
          })}
          <ds.FixedWidthContainer
            bg={ds.brandColors.bg.white}
            py={ds.spacing.s6}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <ds.FixedWidthRow
              display="flex"
              py={0}
              style={{ maxWidth: '100%', margin: '0' }}
            >
              <ds.Column span={12} textAlign="center">
                <H2 color="#202020">{page.valuesTitle}</H2>
                <ChampionValues
                  values={page.values}
                  iconColor={page.valuesIconColor}
                />
              </ds.Column>
            </ds.FixedWidthRow>
            <ds.FixedWidthRow
              display="flex"
              flexDirection="column"
              py={ds.spacing.s5}
              alignItems="center"
            >
              <ds.Column
                span={12}
                textAlign="center"
                py={ds.spacing.s4}
                alignItems="center"
              >
                <H4>{page.communityGuidelinesString}</H4>
              </ds.Column>
              <ds.Column span={12}>
                {page.communityGuideLink && page.communityGuideLinkLabel ? (
                  <AngledButton
                    anchorProps={{
                      target: '_self',
                    }}
                    bg={ds.brandColors.darkRed}
                    focus={ds.brandColors.lightRed}
                    label={page.communityGuideLinkLabel}
                    href={page.communityGuideLink}
                  />
                ) : null}
              </ds.Column>
            </ds.FixedWidthRow>
          </ds.FixedWidthContainer>
          <ds.FixedWidthContainer bg={'#202020'} pt={ds.spacing.s5}>
            <TriangleDiv
              display={['none', 'block', 'block']}
              bg={'#202020'}
              mt={ds.spacing.s4}
            />
          </ds.FixedWidthContainer>
        </ds.Background>
      </Layout>
    </LocationContext.Provider>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        banner: file(relativePath: { eq: "tonitcommunityheader.png" }) {
          childImageSharp {
            fluid(maxWidth: 1440) {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
        }
        imageSections: allFile(
          filter: { name: { regex: "/^communityImage*/" } }
        ) {
          edges {
            node {
              childImageSharp {
                id
                fluid(maxWidth: 1200, quality: 100) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        screenshots: allFile(
          filter: { name: { regex: "/^communityScreenshot*/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              childImageSharp {
                id
                fluid(maxWidth: 400) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        allCommunityJson {
          edges {
            node {
              bannerHeadline
              heroHeading
              introSection {
                title
                titleColor
                header
                headerColor
                bodyColor
                body1
                body2
                body3
                button
                storeButton
                buttonLink
                buttonLabel
                buttonColor
                buttonFocus
                triangle
                bg
                textAlign
              }
              secondarySection {
                title
                titleColor
                header
                headerColor
                bodyColor
                body1
                body2
                body3
                button
                storeButton
                buttonLink
                buttonLabel
                buttonColor
                buttonFocus
                triangle
                bg
                order
                textAlign
                imageAlt
                imageExists
              }
              thirdSection {
                title
                titleColor
                header
                headerColor
                bodyColor
                body1
                body2
                body3
                button
                storeButton
                buttonLink
                buttonLabel
                buttonColor
                buttonFocus
                triangle
                bg
                order
                textAlign
                imageAlt
                imageExists
              }
              lastSection {
                title
                titleColor
                header
                headerColor
                bodyColor
                body1
                body2
                body3
                button
                storeButton
                buttonLink
                buttonLabel
                buttonColor
                buttonFocus
                triangle
                bg
                textAlign
              }
              valuesTitle
              valuesIconColor
              values {
                icon
                title
                value
              }
              communityGuidelinesString
              communityGuideLink
              communityGuideLinkLabel
              imageSections {
                containerColor
                urlDescription
                title
                flip
                reverse
                button
                storeButton
                buttonLabel
                buttonColor
                buttonLink
                focus
                src
                box
                srcDescription
                titleColor
                firstBody
                secondBody
                thirdBody
              }
              pageTitle
              pageDescription
              socialTitle
              socialDescription
              socialImage
            }
          }
        }
      }
    `}
    render={data => <CommunityPage data={data} {...props} />}
  />
)
